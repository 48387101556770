
export const BASEURL = "https://backend.epsumthings.com";
export const IRBASEURL = "http://things.epsumlabs.com:8001";
export const WEBSOCKET_BASEURL = "wss://backend.epsumthings.com";
export const MQTT_URL = "mqtt://epsumthings.epsumlabs.com:1883";
export const MQTT_CLIENT_ID = 'MQTT_FX_Client'

export const RESPONSE_CODE = {
    SUCCESS: 200,
    CREATED: 201,
    ACCEPTED: 202,
    NO_CONTENT: 204,
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500
}

export const maxmSpeed = 55.5556  // meter per sec
export const GOOGLE_MAP_URL = "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDjCX0hCACCUVwfOsI5uWVouJv7rJtGgn0"