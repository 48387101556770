import { bb64encod } from "../redux/security";
// export let baseurl = "https://backend.epsumthings.com";
export let baseurl = "https://backend.epsumthings.com";

// export let baseurl = "http://192.168.0.107:8005";
// export let baseurl = "http://192.168.29.177:8000";



let ws_baseurl = "wss://backend.epsumthings.com";
let irbaseurl = "http://things.epsumlabs.com:8001";
export let MQTT_URL = "mqtt://epsumthings.com:1883";

// export
// let baseurl = "http://3.17.49.10:8000";
// let ws_baseurl = "ws://3.17.49.10:8000";
// let irbaseurl = "http://3.17.49.10:8000";
// export let MQTT_URL = "http://3.17.49.10:8000";

// let baseurl = "https://staging-backend.epsumthings.com";
// let ws_baseurl = "wss://staging-backend.epsumthings.com";
// let irbaseurl = "http://staging-backend.epsumthings.com:8001";
// export let MQTT_URL = "mqtt://staging-backend.epsumthings.com:1883";

if (process.env.REACT_APP_ENV === "staging") {
    baseurl = "https://staging-backend.epsumthings.com";
    ws_baseurl = "wss://staging-backend.epsumthings.com";
    irbaseurl = "http://staging-backend.epsumthings.com:8001";
    MQTT_URL = "mqtt://staging-backend.epsumthings.com:1883";

    console.log("Staging environment")
} else if (process.env.REACT_APP_ENV === "local") {
    console.log("Local environment")
    baseurl = "http://localhost:8002";
    ws_baseurl = "ws://localhost:8002";
    irbaseurl = "http://localhost:8001";
    MQTT_URL = "mqtt://localhost:1883";

} else if (process.env.NODE_ENV === "production" || process.env.REACT_APP_ENV === "production") {
    baseurl = "https://backend.epsumthings.com";
    ws_baseurl = "wss://backend.epsumthings.com";
    irbaseurl = "http://things.epsumlabs.com:8001";
    MQTT_URL = "mqtt://epsumthings.com:1883";
    console.log("Production environment")
}

export const MQTT_CLIENT_ID = 'EPSUMTHINGS_UI'


export const api = {
    user: baseurl + '/user/',
    user_register: baseurl + '/user/register/',
    user_profile: baseurl + '/user/profile/',
    user_logs: baseurl + '/user/logs/',
    org: baseurl + '/organisation/',
    org_user: baseurl + '/organisation/user/',
    project_user: baseurl + '/projects/user/',
    project: baseurl + '/projects/',
    things: baseurl + '/things/',
    things_datalog: baseurl + '/things/datalog/',
    things_schema: baseurl + "/things/schema/",
    camera_list: baseurl + "/camera/",
    verify: baseurl + "/user/verify/",
    camera: baseurl + "/camera/",
    webhooks: baseurl + "/things/webhooks/",
    process: baseurl + "/process/",
    profile: baseurl + '/user/',
    datalog_export: baseurl + '/things/datalog/',
}

export const NOTIFICATION_COLOUR = {
    SUCCESS: 'success',
    WARNING: 'warning',
    INFO: 'info',
    DANGER: 'error'
}

/**
 * fetchApi calls the api and communcates data using callbacks. Returns null when get request has a body
 * 
 * @param {string} url - URL to which fetch api 
 * @param {string} headers - Headers
 * @param {string} method - get | post | put | delete 
 * @param {object} body - API call using body
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 * 
 */
export const DEFAULT_DATA = {
    count: 0,
    next: null,
    previous: null,
    results: []
}


export const RESPONSE_TYPE = {
    JSON: 'json',
    NULL: null,
    BLOB: 'blob'
}

const fetchApi = (url, headers, method, body = null, successCallback, failureCallback, errorCallback, responseType = RESPONSE_TYPE.JSON) => {
    let options = null;

    if (method === "get" && body !== null) {
        errorCallback("GET request does not support body")
        return null
    } else if (method === "get") {

        options = {

            method: method,
            headers: headers
        }
    } else {
        options = {
            method: method,
            body: JSON.stringify(body),
            headers: headers
        }
    }

    fetch(url, options)
        .then(response => {
            if (response.status >= 400) {
                return response
            } else {
                switch (responseType) {
                    case RESPONSE_TYPE.JSON:
                        return response.json();
                    case RESPONSE_TYPE.BLOB:
                        return response.blob();
                    case RESPONSE_TYPE.NULL:
                        return DEFAULT_DATA
                }
            }
        })
        .then(responseJson => {
            if (responseJson.type === 'cors') {
                apiErrorHandler(responseJson, errorCallback)
            } else {
                if (responseJson.status === "success") {
                    successCallback(responseJson)
                } else {
                    let key = Object.keys(responseJson)
                    if (key.includes('msg')) {
                        key = key[key.indexOf('msg')]
                    }
                    else {
                        key = 0
                    }
                    failureCallback(responseJson[key])
                }
            }
        }).catch(error => {
            errorCallback(error)
        })

}


export const RESPONSE_CODE = {
    SUCCESS: 200,
    CREATED: 201,
    ACCEPTED: 202,
    NO_CONTENT: 204,
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500
}
export function apiErrorHandler(response, errorCallBack) {
    switch (response.status) {
        case RESPONSE_CODE.INTERNAL_SERVER_ERROR:
            return response.statusText;
        default:
            getApiErrorMessage(response, errorCallBack);
            break;
    }
}

function getApiErrorMessage(response, errorCallBack) {
    (response.json()).then((data) => {

        let key = Object.keys(data)
        if (key.includes('msg')) {
            key = key[key.indexOf('msg')]
        } else {
            key = key[0]
        }
        if (typeof data[key] === 'string')
            return errorCallBack(data[key])
        if (data.length > 0) {
            data = data[0]
        }
        let keys = Object.keys(data)
        if (keys.length > 0) {
            keys = keys[0]
        }
        return errorCallBack(data[keys][0])
    })
}

export const getGroups = (token, org_id, successCallback, failureCallback, errorCallback) => {
    // fetchApi(`${baseurl}/groups/${org_id}`,
    fetchApi(`${baseurl}/groups/`,

        {

            "Authorization": token,
            "Access-Control-Allow-Origin": "*",
            "mode": 'no-cors', "Content-Type": "application/json",
            "accept": "application/json"

        }, "get", null, successCallback, failureCallback, errorCallback)
}

export const assignGroupsToUser = (token, body, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/groups/user/`,
        {

            "Authorization": token,
            "Access-Control-Allow-Origin": "*",
            "mode": 'no-cors', "Content-Type": "application/json",
            "accept": "application/json"

        }, "post", body, successCallback, failureCallback, errorCallback)
}

export const deleteGroupUser = (token, body, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/groups/user/`,
        {

            "Authorization": token,
            "Access-Control-Allow-Origin": "*",
            "mode": 'no-cors', "Content-Type": "application/json",
            "accept": "application/json"

        }, "delete", body, successCallback, failureCallback, errorCallback)
}

export const getAssignGroupsUser = (token, search, org_id, successCallback, failureCallback, errorCallback) => {
    // fetchApi(`${baseurl}/groups/user/${org_id}${search}`,
    fetchApi(`${baseurl}/groups/user/${search}`,
        {

            "Authorization": token,
            "Access-Control-Allow-Origin": "*",
            "mode": 'no-cors', "Content-Type": "application/json",
            "accept": "application/json"

        }, "get", null, successCallback, failureCallback, errorCallback)
}


export const setProperties = (token, body, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/groups/properties/`,
        {

            "Authorization": token,
            "Access-Control-Allow-Origin": "*",
            "mode": 'no-cors', "Content-Type": "application/json",
            "accept": "application/json"

        }, "post", body, successCallback, failureCallback, errorCallback)
}

export const deleteProperties = (token, body, successCallback, failureCallback, errorCallback) => {
    if (window.confirm("Are you sure want to delete this property ?")) {
        fetchApi(`${baseurl}/groups/properties/`,
            {

                "Authorization": token,
                "Access-Control-Allow-Origin": "*",
                "mode": 'no-cors', "Content-Type": "application/json",
                "accept": "application/json"

            }, "delete", body, successCallback, failureCallback, errorCallback)
    }
}


export const updateProperties = (token, body, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/groups/properties/`,
        {

            "Authorization": token,
            "Access-Control-Allow-Origin": "*",
            "mode": 'no-cors', "Content-Type": "application/json",
            "accept": "application/json"

        }, "put", body, successCallback, failureCallback, errorCallback)
}



export const updateGroups = (token, body, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/groups/`,
        {

            "Authorization": token,
            "Access-Control-Allow-Origin": "*",
            "mode": 'no-cors', "Content-Type": "application/json",
            "accept": "application/json"

        }, "put", body, successCallback, failureCallback, errorCallback)
}


export const getProperties = (token, org_id, successCallback, failureCallback, errorCallback) => {
    // fetchApi(`${baseurl}/groups/properties/${org_id}`,
    fetchApi(`${baseurl}/groups/properties/`,
        {

            "Authorization": token,
            "Access-Control-Allow-Origin": "*",
            "mode": 'no-cors', "Content-Type": "application/json",
            "accept": "application/json"

        }, "get", null, successCallback, failureCallback, errorCallback)
}

export const downloadCSV_File = (token, project_id, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/things/bulk/sample-csv/${project_id}`,
        {

            "Authorization": token,
            "Access-Control-Allow-Origin": "*",
            "mode": 'no-cors', "Content-Type": "application/json",
            "accept": "application/json"

        }, "get", null, successCallback, failureCallback, errorCallback)
}

export const getAlarmsThingWise = (token, username, project_id, thing_id, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/projects/${project_id}/${thing_id}/alarm/`,
        {
            "Authorization": token,
            "user": username,
            "Access-Control-Allow-Origin": "*",
            "mode": 'no-cors', "Content-Type": "application/json",
            "accept": "application/json"

        },
        "get", null, successCallback, failureCallback, errorCallback)
}

export const postGroups = (token, body, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/groups/`,
        {
            "Authorization": token,
            "Access-Control-Allow-Origin": "*",
            "mode": 'no-cors', "Content-Type": "application/json",
            "accept": "application/json"

        }, "post", body, successCallback, failureCallback, errorCallback)
}

export const deleteGroups = (token, body, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/groups/`,
        {
            "Authorization": token,
            "Access-Control-Allow-Origin": "*",
            "mode": 'no-cors', "Content-Type": "application/json",
            "accept": "application/json"

        }, "delete", body, successCallback, failureCallback, errorCallback)
}

/**
 * Get user profile
 * 
 * @param {string} token Access token
 * @param {string} user Username
 * @param {function} successCallback Success Callback function
 * @param {function} failureCallback Failure Callback function
 * @param {function} errorCallback Error callback function
 */

const getUserProfile = (token, username, successCallback, failureCallback, errorCallback) => {
    fetchApi(api.user_profile,
        {
            "Authorization": token,
            "user": username,
            "Access-Control-Allow-Origin": "*",
            "mode": 'no-cors', "Content-Type": "application/json",
            "accept": "application/json"

        }, "get", null, successCallback, failureCallback, errorCallback)

}

/**
 * Get user profile
 * 
 * @param {string} token Access token
 * @param {string} user Username
 * @param {function} successCallback Success Callback function
 * @param {function} failureCallback Failure Callback function
 * @param {function} errorCallback Error callback function
 */

const getProfileData = (token, successCallback, failureCallback, errorCallback) => {
    fetchApi(api.profile,
        {
            "Authorization": token,
            "Access-Control-Allow-Origin": "*",
            "mode": 'no-cors', "Content-Type": "application/json",
            "accept": "application/json"
        }, "get", null, successCallback, failureCallback, errorCallback)

}


/**
 * Create project
 * 
 * @param {string} project_name - Project name to be registered
 * @param {string} project_desc - Project name to be registered
 * @param {string} username - username to be passed through header
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 * 
 */
const createProject = (project_name, project_desc, token, username, successCallback, failureCallback, errorCallback) => {
    fetchApi(api.project_user,
        {
            "Authorization": token,
            "user": username


        }, "post", {
        "project_name": project_name,
        "project_desc": project_desc
    }, successCallback, failureCallback, errorCallback)
}

/**
 * Create project
 * 
 * @param {string} project_name - Project name to be registered
 * @param {string} project_desc - Project name to be registered
 * @param {string} username - username to be passed through header
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 * 
 */

const deleteProject = (accessToken, projectId, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/projects/${projectId}/`,
        {
            "Authorization": accessToken
        }, "delete", {
        "project_id": projectId,
    }, successCallback, failureCallback, errorCallback)
}



const updateProject = (project_id, project_name, project_desc, token, successCallback, failureCallback, errorCallback) => {

    fetchApi(api.project_user,
        {
            "Authorization": token

        }, "PUT", {
        "project_id": project_id,
        "project_name": project_name,
        "project_desc": project_desc
    }, successCallback, failureCallback, errorCallback)

}


/**
* List of projects
* 
* 
* 
* @param {string} token - Bearer token for authorization
* @param {string} username - username to be passed through header
* @param {function} successCallback - Callback upon successful api fetch
* @param {function} failureCallback - Callback upon failed api fetch
* @param {function} errorCallback - Callback upon fetch exception
* 
*/
const listProjects = (token, pageNumber, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/projects/user/?page=${pageNumber}`,
        {

            "Authorization": token,
            "Access-Control-Allow-Origin": "*",
            "mode": 'no-cors', "Content-Type": "application/json",
            "accept": "application/json"

        }, "get", null, successCallback, failureCallback, errorCallback)
}

export const searchlistProjects = (token, search, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/projects/user/?search=${search}`,
        {

            "Authorization": token,
            "Access-Control-Allow-Origin": "*",
            "mode": 'no-cors', "Content-Type": "application/json",
            "accept": "application/json"

        }, "get", null, successCallback, failureCallback, errorCallback)
}


/**
* List of projects with search support
* 
* 
* 
* @param {string} token - Bearer token for authorization
* @param {string} username - username to be passed through header
* @param {function} successCallback - Callback upon successful api fetch
* @param {function} failureCallback - Callback upon failed api fetch
* @param {function} errorCallback - Callback upon fetch exception
* 
*/
export const listProjectsWithSearch = (token, pageNumber, searchText, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/projects/user/?page=${pageNumber}&search=${searchText}`,
        {

            "Authorization": token,
            "Access-Control-Allow-Origin": "*",
            "mode": 'no-cors', "Content-Type": "application/json",
            "accept": "application/json"

        }, "get", null, successCallback, failureCallback, errorCallback)
}


/**
* List of N number of projects
* 
* 
* 
* @param {string} token - Bearer token for authorization
* @param {string} username - username to be passed through header
* @param {number} project_count - Number of latest projects to be fetched
* @param {function} successCallback - Callback upon successful api fetch
* @param {function} failureCallback - Callback upon failed api fetch
* @param {function} errorCallback - Callback upon fetch exception
* 
*/
const listNProjects = (token, username, project_count, successCallback, failureCallback, errorCallback) => {
    fetchApi(api.project_user,
        {
            "Authorization": token,
            "user": username,
            "Access-Control-Allow-Origin": "*",
            "mode": 'no-cors', "Content-Type": "application/json",
            "accept": "application/json"

        }, "get", null, successCallback, failureCallback, errorCallback)
}

/**
 *
 * Get a list of cameras for a user
 * 
 * @param {string} token - Bearer token for authorization
 * @param {string} username - username to be passed through header
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
const listCameras = (token, project_id, pageNumber, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/camera/all/${project_id}/?page=${pageNumber}`,
        {
            "Authorization": token,
            "Access-Control-Allow-Origin": "*",
            "mode": 'no-cors', "Content-Type": "application/json",
            "accept": "application/json"

        }, "get", null, successCallback, failureCallback, errorCallback
    )
}


/**
 *
 * Get a list of Things for a given project
 * 
 * @param {string} token - Bearer token for authorization
 * @param {string} username - username to be passed through header
 * @param {string} project - Project id from where things to be fetched
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
const listThings = (token, project, pagecount, search = '', successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/projects/${project}/things/?page=${pagecount}&search=${search}`,
        {
            "Authorization": token,
        },
        "get", null, successCallback, failureCallback, errorCallback
    )
}


/**
 *
 * Register a thing
 * 
 * @param {string} token - Bearer token for authorization
 * @param {string} username - username to be passed through header
 * @param {string} project - Project id from where things to be fetched
 * @param {string} thingname - Name of thing
 * @param {string} thingid - Thing id
 * @param {object} schema - Schema of the thing
 * @param {string} description - Thing description
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
function registerThing(token, username, project, thingname, thingid, schema, description, transfer, successCallback, failureCallback, errorCallback) {
    let register_thing_payload = {
        "project_id": project,
        "thing_name": thingname,
        "thing_id": thingid,
        "schema": schema,
        "desc": description,
        "product": transfer,
    }

    // console.log(JSON.stringify(register_thing_payload))

    fetchApi(api.things, {
        "Authorization": token,
        "user": username
    },
        "post", register_thing_payload, successCallback, failureCallback, errorCallback);
}

/**
 * For regenerating Image stream key
 * 
 * @param {*} token  Bearer token for authorization
 * @param {*} project_id Project id from where things to be fetched
 * @param {*} imagestream_id Image stream ID
 * @param {*} successCallback Success callback
 * @param {*} failureCallback Failure callback
 * @param {*} errorCallback Error callback
 */
export function regenerateImageStream(token, project_id, imagestream_id, successCallback, failureCallback, errorCallback) {
    fetchApi(`${baseurl}/imagestream/${project_id}/streams/${imagestream_id}/`, {
        "authorization": token
    },
        "POST", {}, successCallback, failureCallback, errorCallback);
}

/**
 *
 * Delete  a Project thing
 * 
 * @param {string} token - Bearer token for authorization
 * @param {string} username - username to be passed through header
 * @param {string} project - Project id from where things to be fetched
 * @param {string} thingname - Name of thing
 * @param {string} thingid - Thing id
 * @param {object} schema - Schema of the thing
 * @param {string} description - Thing description
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
function deleteProjectThing(token, projectId, thingId, successCallback, failureCallback, errorCallback) {
    let register_thing_payload = {
        "things_id": thingId,
    }

    fetchApi(`${baseurl}/projects/${projectId}/things/`, {
        "Authorization": token,
    },
        "delete", register_thing_payload, successCallback, failureCallback, errorCallback);
}

/**
 *
 * Update  a Project thing
 * 
 * @param {string} token - Bearer token for authorization
 * @param {string} username - username to be passed through header
 * @param {string} project - Project id from where things to be fetched
 * @param {string} thingname - Name of thing
 * @param {string} thingid - Thing id
 * @param {object} schema - Schema of the thing
 * @param {string} description - Thing description
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
function updateThing(token, project, thingname, thingid, schema, description, transfer_status, successCallback, failureCallback, errorCallback) {
    let register_thing_payload = {
        "project_id": project,
        "thing_name": thingname,
        "thing_id": thingid,
        "schema": schema,
        "desc": description,
        "product": transfer_status,
    }


    fetchApi(api.things, {
        "Authorization": token,
    },
        "Put", register_thing_payload, successCallback, failureCallback, errorCallback);
}

/**
 *
 * Get thing details
 * 
 * @param {string} token - Bearer token for authorization
 * @param {string} project - Project id 
 * @param {string} thingid - Thing id
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */

function getThingDetails(token, project, thingid, successCallback, failureCallback, errorCallback) {
    fetchApi(`${api.things}?project_id=${project}&thing_id=${thingid}`, {
        "Authorization": token,
    }, 'get', null, successCallback, failureCallback, errorCallback)
}


/**
 *
 * Get thing datalog
 * 
 * @param {string} token - Bearer token for authorization
 * @param {string} username - username to be passed through header
 * @param {string} project - Project id 
 * @param {string} thingid - Thing id
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
function getThingDatalog(token, username, project, thingid, from_date, to_date, successCallback, failureCallback, errorCallback) {
    fetchApi(api.things_datalog, {
        "Authorization": token,
        "user": username
    }, 'post', {
        "from_date": from_date,
        "to_date": to_date,
        "thing_id": thingid,
        "project": project
    }, successCallback, failureCallback, errorCallback)
}

const onExportCSV = (accessToken, project_id, thing_id, date, yesterday_date, successCallback, failureCallback, errorCallback) => {
    fetchApi(api.datalog_export,
        {
            'Authorization': accessToken
        }, 'PATCH', {
        "thing_id": thing_id,
        "project": project_id,
        "from_date": yesterday_date,
        "to_date": date
    }, successCallback, failureCallback, errorCallback)
}

/**
 *
 * Verify existing access token
 * 
 * @param {string} token - Bearer token for authorization
 * @param {string} username - username to be passed through header
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
function verifyToken(token, username, successCallback, failureCallback, errorCallback) {
    // debugger
    fetchApi(api.verify, {
        "Authorization": token,
        "user": username
    }, 'get', null, successCallback, failureCallback, errorCallback)
}

/**
 *
 * Create new camera
 * 
 * @param {string} token - Bearer token for authorization
 * @param {string} username - username to be passed through header
 * @param {string} camera_name - Given name of the camera
 * @param {string} camera_type - Type of camera
 * @param {string} project_id - Project id of the camera associated with
 * @param {string} local_url - Local url where the visual data is collected from 
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
function createCamera(token, camera_name, camera_desc, camera_type, project_id, local_url, successCallback, failureCallback, errorCallback) {
    fetchApi(api.camera, {
        "Authorization": token,
    }, 'post', {
        "camera_name": camera_name,
        "camera_desc": camera_desc,
        "camera_type": camera_type,
        'project_id': project_id,
        'local_url': local_url
    }, successCallback, failureCallback, errorCallback)
}

/**
 *
 * Create new camera
 * 
 * @param {string} token - Bearer token for authorization
 * @param {string} username - username to be passed through header
 * @param {string} camera_name - Given name of the camera
 * @param {string} camera_type - Type of camera
 * @param {string} project_id - Project id of the camera associated with
 * @param {string} local_url - Local url where the visual data is collected from 
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
function modifyCamera(token, camera_name, camera_desc, camera_type, camera_id, local_url, successCallback, failureCallback, errorCallback) {
    fetchApi(api.camera, {
        "Authorization": token,
    }, 'put', {
        "camera_name": camera_name,
        "camera_desc": camera_desc,
        "camera_type": camera_type,
        'camera_id': camera_id,
        'local_url': local_url
    }, successCallback, failureCallback, errorCallback)
}
/**
 *
 * Create new camera
 * 
 * @param {string} token - Bearer token for authorization
 * @param {string} username - username to be passed through header
 * @param {string} camera_name - Given name of the camera
 * @param {string} camera_type - Type of camera
 * @param {string} project_id - Project id of the camera associated with
 * @param {string} local_url - Local url where the visual data is collected from 
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
function deleteCamera(token, camera_id, successCallback, failureCallback, errorCallback) {
    fetchApi(api.camera + camera_id + '/', {
        "Authorization": token,
    }, 'delete', null, successCallback, failureCallback, errorCallback)
}

/**
 *
 * Create Trend
 * 
 * @param {string} token - Bearer token for authorization
 * @param {string} username - username to be passed through header
 * @param {string} name - Given name of the Trend
 * @param {string} desc - Trend Description
 * @param {string} project_id - Project id of the camera associated with
 * @param {string} xaxis - xaxis parameters 
 * @param {string} yaxis - yaxis parameters 
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
function createTrend(token, username, name, desc, project_id, xaxis, yaxis, successCallback, failureCallback, errorCallback) {
    fetchApi(api.project + project_id + "/trend/", {
        "Authorization": token,
        "user": username
    }, 'post', {
        "trend_name": name,
        "trend_desc": desc,
        "xaxis": xaxis,
        'yaxis': yaxis
    }, successCallback, failureCallback, errorCallback)
}


/**
 *
 * Get Trends for a project
 * 
 * @param {string} token - Bearer token for authorization
 * @param {string} username - username to be passed through header
 * @param {string} project_id - Project id of the camera associated with
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
function getTrends(token, username, project_id, successCallback, failureCallback, errorCallback) {
    fetchApi(`${api.project}${project_id}/trend/`, {
        "Authorization": token,
        "user": username
    }, 'get', null, successCallback, failureCallback, errorCallback)

}

/**
 * Get Trend data using project and trend id
 * 
 * @param {string} token - Bearer token for authorization
 * @param {string} username - username to be passed through header
 * @param {string} project_id - Project id of the Trend associated with
 * @param {string} trend_id - Trend id 
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 * 
 */
function getTrendData(token, username, project_id, trend_id, from, to, successCallback, failureCallback, errorCallback) {
    fetchApi(`${api.project}${project_id}/trend/${trend_id}?from=${from}&to=${to}`, {
        "Authorization": token,
        "user": username
    }, 'get', null, successCallback, failureCallback, errorCallback)
}

/**
 * 
 * @param {string} token - Bearer token for authorization
 * @param {string} username username to be passed through header
 * @param {string} project_id Project id of the Trend associated with
 * @param {string} trend_id Trend id
 * @param {function} successCallback successCallback - Callback upon successful api fetch
 * @param {function} failureCallback failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback errorCallback - Callback upon fetch exception
 */
function deleteTrend(token, username, project_id, trend_id, successCallback, failureCallback, errorCallback) {
    fetchApi(`${api.project}${project_id}/trend/${trend_id}`, {
        "Authorization": token,
        "user": username
    }, 'delete', { project_id: project_id }, successCallback, failureCallback, errorCallback)
}


/**
 *
 * Create Alarm for a project
 * 
 * @param {string} token - Bearer token for authorization
 * @param {string} username - username to be passed through header
 * @param {string} project_id - Project id with which alarm is associated
 * @param {string} alarm_name - Alarm name
 * @param {string} alarm_desc - Alarm description
 * @param {string} thing_id - Thing id associated with the alarm
 * @param {boolean} alarm_status - Alarm status on/off
 * @param {int} thing_pin - Thing pin that for which alarm is set for
 * @param {string} h - warning high value 
 * @param {string} hh - Alarm high panic value
 * @param {string} l - Warning low value
 * @param {string} ll - Alarm low panic value
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
const createAlarm = (token, username, project_id, alarm_name, alarm_desc, thing_id, alarm_status, thing_pin, h, hh, l, ll, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${api.project}${project_id}/alarm/`,
        {
            "Authorization": token,
            "user": username
        },
        'post',
        {
            alarm_name: alarm_name,
            alarm_desc: alarm_desc,
            project_id: project_id,
            thing_id: thing_id,
            alarm_status: alarm_status,
            thing_pin: thing_pin,
            h: h, hh: hh, l: l, ll: ll
        }, successCallback, failureCallback, errorCallback)

}


/**
 *
 * Get Alarms for a project
 * 
 * @param {string} token - Bearer token for authorization
 * @param {string} usernprojectame - username to be passed through header
 * @param {string} project_id - Project id of the camera associated with
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
function getAlarms(token, username, project_id, successCallback, failureCallback, errorCallback) {
    fetchApi(`${api.project}${project_id}/alarm/`,
        {
            "Authorization": token,
            "user": username
        }, 'get', null, successCallback, failureCallback, errorCallback)

}


/**
 *
 * Delete Alarm for a project
 * 
 * @param {string} token - Bearer token for authorization
 * @param {string} username - username to be passed through header
 * @param {string} project_id - Project id of the camera associated with
 * @param {string} alarm_id - Project id of the camera associated with
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
function deleteAlarm(token, username, project_id, alarm_id, successCallback, failureCallback, errorCallback) {
    fetchApi(`${api.project}${project_id}/alarm/`, {
        "Authorization": token,
        "user": username
    }, 'delete', { "alarm_id": alarm_id }, successCallback, failureCallback, errorCallback)

}


/**
 *
 * Modify Alarm for a project
 * 
 * @param {string} token - Bearer token for authorization
 * @param {string} username - username to be passed through header
 * @param {string} project_id - Project id of the camera associated with
 * @param {string} alarm_id - Project id of the camera associated with
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
function modifyAlarm(token, project_id, alarm_id, name, description, alarm_status, thing_id, thing_pin, h, hh, l, ll, successCallback, failureCallback, errorCallback) {
    fetchApi(`${api.project}${project_id}/alarm/`, {
        "Authorization": token,
    }, 'put',
        {
            "alarm_id": alarm_id, "alarm_name": name, "alarm_desc": description, "alarm_status": alarm_status, thing_id: thing_id, thing_pin: thing_pin, h: h, hh: hh, l: l, ll: ll

        }, successCallback, failureCallback, errorCallback)

}

/**
 * Update user profile
 * 
 * @param {string} token Bearer Access token
 * @param {string} username - Username 
 * @param {string} name - User Full name
 * @param {string} phone - Phone number
 * @param {string} country - Country
 * @param {string} timezone - Time zone
 * @param {function} successCallback - Success callback function
 * @param {function} failureCallback - Failure callback function
 * @param {function} errorCallback - Error Callback function
 */
function modifyUserProfile(token, username, name, phone, country, timezone, successCallback, failureCallback, errorCallback) {
    fetchApi(api.user_profile, {
        "Authorization": token,
        "user": username
    }, 'post', { name: name, phone: phone, country: country, timezone: timezone },
        successCallback, failureCallback, errorCallback)

}

/**
 * Update user password
 * 
 * @param {string} token - Bearer access token
 * @param {string} username - Username
 * @param {string} oldpassword - Old password of the user logged in
 * @param {string} newpassword - New Password of the user logged in
 * @param {function} successCallback - Success callback function
 * @param {function} failureCallback - Failure callback function
 * @param {function} errorCallback - Error Callback function
 */
function updateUserPassword(token, username, oldpassword, newpassword, successCallback, failureCallback, errorCallback) {
    fetchApi(
        api.user_profile, {
        "Authorization": token,
        "user": username
    }, 'PATCH', {
        old_password: bb64encod(oldpassword),
        new_password: bb64encod(newpassword),
    }, successCallback, failureCallback, errorCallback
    )
}

/**
 * Get User logs
 * 
 * @param {string} token - Bearer access token
 * @param {string} username - Username
 * @param {function} successCallback - Success callback function
 * @param {function} failureCallback - Failure callback function
 * @param {function} errorCallback - Error Callback function
 */
// function getUserLogs(token, username, successCallback, failureCallback, errorCallback) {
//     fetchApi(
//         api.user_logs, {
//         "Authorization": token,
//         "user": username
//     }, 'get', null, successCallback, failureCallback, errorCallback
//     )
// }
function getUserLogs(token, pageNo, successCallback, failureCallback, errorCallback) {
    fetchApi(`${baseurl}/user/logs/?page=${pageNo}`,
        {
            "Authorization": token,
        }, 'get', null, successCallback, failureCallback, errorCallback)
}

/**
 * Creates a new app for the user
 * 
 * @param {string} token 
 * @param {string} username Username
 * @param {string} app_name App name provided by the user
 * @param {array} scopes List of scopes the app has access to
 * @param {function} successCallback Success callback function
 * @param {function} failureCallback Failure callback function
 * @param {function} errorCallback Error callback function
 */
function createApp(token, username, app_name, scopes, successCallback, failureCallback, errorCallback) {
    fetchApi(
        `${baseurl}/api/v1/app/`, {
        "Authorization": token,
        "user": username
    }, 'post', {
        app_name: app_name, scopes: scopes
    }, successCallback, failureCallback, errorCallback
    )

}

function getAllScopes(token, successCallback, failureCallback, errorCallback) {
    fetchApi(
        `${baseurl}/api/v1/scopes/`, {
        "Authorization": token,
    }, 'get', null, successCallback, failureCallback, errorCallback
    )

}


/**
 * Get registered user apps
 * 
 * @param {string} token Access bearer token 
 * @param {string} username Username
 * @param {function} successCallback Success callback function
 * @param {function} failureCallback Failure callback function
 * @param {function} errorCallback Error callback function
 */
function getAppsList(token, username, successCallback, failureCallback, errorCallback) {
    fetchApi(
        `${baseurl}/api/v1/app/`, {
        "Authorization": token,
        "user": username
    }, 'get', null, successCallback, failureCallback, errorCallback
    )

}

/**
 * Revoke / delete an existing app
 * 
 * @param {string} token Access bearer token
 * @param {string} username Username
 * @param {string} appid Appid to be removed
 * @param {function} successCallback Success callback function
 * @param {function} failureCallback Failure callback function
 * @param {function} errorCallback Error callback function
 */
function revokeApp(token, username, appid, successCallback, failureCallback, errorCallback) {
    fetchApi(
        `${baseurl}/api/v1/app/`, {
        "Authorization": token,
        "user": username
    }, 'delete', { app_id: appid }, successCallback, failureCallback, errorCallback
    )
}

/**
 * 
 * @param {function} successCallback Success callback
 * @param {function} failureCallback Failure callback
 * @param {function} errorCallback Error callback
 */
function getBrands(successCallback, failureCallback, errorCallback) {
    fetchApi(
        `${irbaseurl}/api/brands`, {
        "Content-type": 'application/json'
    }, 'get', null, successCallback, failureCallback, errorCallback
    )
}

/**
 * 
 * @param {string} username 
 * @param {string} token 
 * @param {string} project_id 
 * @param {function} successCallback 
 * @param {function} failureCallback 
 * @param {function} errorCallback 
 */
function getIRBlasters(token, project_id, successCallback, failureCallback, errorCallback) {
    fetchApi(
        `${baseurl}/irblaster/?project_id=${project_id}`, {
        "Authorization": token,
    }, 'get', null,
        successCallback, failureCallback, errorCallback
    )

}
/**
 * 
 * @param {string} username 
 * @param {string} token 
 * @param {string} project_id 
 * @param {function} successCallback 
 * @param {function} failureCallback 
 * @param {function} errorCallback 
 */
function deleteIRBlasters(token, project_id, irblaster_id, successCallback, failureCallback, errorCallback) {
    fetchApi(
        `${baseurl}/irblaster/`, {
        "Authorization": token,
    }, 'delete', {
        "project_id": project_id,
        "irblaster_id": irblaster_id
    }
        ,
        successCallback, failureCallback, errorCallback
    )

}


/**
 * 
 * @param {string} name IRBlaster name
 * @param {string} desc IRBlaster description
 * @param {string} username 
 * @param {string} token 
 * @param {string} project_id 
 * @param {function} successCallback 
 * @param {function} failureCallback 
 * @param {function} errorCallback 
 */
function registerIRBlaster(name, desc, username, token, project_id, successCallback, failureCallback, errorCallback) {
    fetchApi(
        `${baseurl}/irblaster/`, {
        "Authorization": token,
        "user": username
    }, 'post', { irblaster_name: name, irblaster_desc: desc, project_id: project_id },
        successCallback, failureCallback, errorCallback
    )

}

/**
 * 
 * @param {string} name IRBlaster name
 * @param {string} desc IRBlaster description
 * @param {string} username 
 * @param {string} token 
 * @param {string} project_id 
 * @param {function} successCallback 
 * @param {function} failureCallback 
 * @param {function} errorCallback 
 */
function updateIRBlaster(name, desc, username, token, irblaster_id, project_id, successCallback, failureCallback, errorCallback) {
    fetchApi(
        `${baseurl}/irblaster/`, {
        "Authorization": token,
        "user": username
    }, 'put', { irblaster_name: name, irblaster_desc: desc, project_id: project_id, irblaster_id: irblaster_id },
        successCallback, failureCallback, errorCallback
    )

}

const createWebHook = (token, thing_id, project_id, method, webhooks_type, end_point, header, body, extra, successCallback, failureCallback, errorCallback) => {
    fetchApi(api.webhooks,
        {
            "Authorization": token,


        }, "post", {
        "thing_id": thing_id,
        "project_id": project_id,
        "method": method,
        "webhooks_type": webhooks_type,
        "end_point": end_point,
        "header": header,
        "body": body,
        "extra": extra
    }, successCallback, failureCallback, errorCallback)
}

const getWebHook = (token, thing_id = '', project_id = '', webhooks_id = '', pageNumber, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/things/webhooks/?webhook_id=${webhooks_id}&thing_id=${thing_id}&project_id=${project_id}&page=${pageNumber}`,
        {
            "Authorization": token,


        }, "get", null, successCallback, failureCallback, errorCallback)
}

const updateWebHook = (token, webhooks_id, thing_id, project_id, method, webhooks_type, end_point, header, body, extra, successCallback, failureCallback, errorCallback) => {
    fetchApi(api.webhooks,
        {
            "Authorization": token,


        }, "put", {
        "webhooks_id": webhooks_id,
        "thing_id": thing_id,
        "project_id": project_id,
        "method": method,
        "webhooks_type": webhooks_type,
        "end_point": end_point,
        "header": header,
        "body": body,
        "extra": extra
    }, successCallback, failureCallback, errorCallback)
}

const deleteWebHook = (token, webhooks_id, project_id, successCallback, failureCallback, errorCallback) => {
    fetchApi(api.webhooks,
        {
            "Authorization": token,


        }, "delete", {
        "project_id": project_id,
        "webhooks_id": webhooks_id
    }, successCallback, failureCallback, errorCallback)
}

/**
 * Function to Get Process List using projectId
 *
 * @param {string} project_id the id of the project
 * @param {string} token token
 * @param {function} successCallback Successcallback function
 * @param {function} failureCallback 
 * @param {function} errorCallback 
 */

const getProcess = (token, project_id = '', successCallback, failureCallback, errorCallback) => {
    fetchApi(api.process + '?project_id=' + project_id,
        {
            "Authorization": token,


        }, "get", null, successCallback, failureCallback, errorCallback)
}

/**
 * Function to update Process List using projectId
 *
 * @param {string} project_id the id of the project
 * @param {string} process_id the id of the process
 * @param {string} token token
 * @param {function} successCallback Successcallback function
 * @param {function} failureCallback 
 * @param {function} errorCallback 
 */

const updateProcess = (token, project_id, process_id, process_name, successCallback, failureCallback, errorCallback) => {
    fetchApi(api.process,
        {
            "Authorization": token


        }, 'put',
        {
            "process_name": process_name,
            "project_id": project_id,
            "process_id": process_id
        }, successCallback, failureCallback, errorCallback)
}

/**
 * Function to update Process List using projectId
 *
 * @param {string} project_id the id of the project
 * @param {string} process_id the id of the process
 * @param {string} token token
 * @param {function} successCallback Successcallback function
 * @param {function} failureCallback 
 * @param {function} errorCallback 
 */

const createProcess = (token, project_id, process_name, successCallback, failureCallback, errorCallback) => {
    fetchApi(api.process,
        {
            "Authorization": token


        }, 'post',
        {
            "process_name": process_name,
            "project_id": project_id
        }, successCallback, failureCallback, errorCallback)
}
/**
 * Function to Remove Process List using projectId
 *
 * @param {string} project_id the id of the project
 * @param {string} process_id the id of the process
 * @param {string} token token
 * @param {function} successCallback Successcallback function
 * @param {function} failureCallback 
 * @param {function} errorCallback 
 */

const removeProcess = (token, project_id, process_id, successCallback, failureCallback, errorCallback) => {
    fetchApi(api.process,
        {
            "Authorization": token


        }, 'delete',
        {
            "project_id": project_id,
            "process_id": process_id
        }, successCallback, failureCallback, errorCallback)
}

/**
 * Get process identified by ID
 * 
 * @param {string} token Access token
 * @param {string} project_id Project ID to which process belongs to
 * @param {string} process_id Process ID to get
 * @param {function} successCallback Success callback function
 * @param {function} failureCallback failure callback function
 * @param {function} errorCallback Error Callback function
 */
export const getProcessByID = (token, project_id, process_id, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${api.process}?project_id=${project_id}&process_id=${process_id}`,
        {
            "Authorization": token
        }, 'get', null, successCallback, failureCallback, errorCallback)
}

/**
 * Save process flow
 * 
 * @param {string} token Access token
 * @param {string} project_id Project ID to which process belongs to
 * @param {string} process_id Process ID to get
 * @param {function} successCallback Success callback function
 * @param {function} failureCallback failure callback function
 * @param {function} errorCallback Error Callback function
 */
export const saveProcessFlow = (token, project_id, process_id, flow, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${api.process}flow/`,
        {
            "Authorization": token
        }, 'post', {
        project_id: project_id,
        process_id: process_id,
        flow: flow
    }, successCallback, failureCallback, errorCallback)
}

/**
 * Function to Control thing using thingid
 * 
 * @param {string} thingid Thingid of the thing to be controlled
 * @param {string} status action status for controlling
 * @param {string} username username
 * @param {string} token token
 * @param {function} successCallback Successcallback function
 * @param {function} failureCallback 
 * @param {function} errorCallback 
 */
function controlThing(thingid, status, username, token, successCallback, failureCallback, errorCallback) {
    fetchApi(`${baseurl}/things/control/`, {
        "Authorization": token,
        'user': username
    }, 'post', { thingid: thingid, status: status }, successCallback, failureCallback, errorCallback)
}

export function getACLs(username, token, successCallback, failureCallback, errorCallback) {
    fetchApi(`${baseurl}/management/acl/`, {
        "Authorization": token,
        'user': username
    }, 'get', null, successCallback, failureCallback, errorCallback)
}

/**
 * Forgot password request 
 * 
 * @param {string} email Email for forgot password
 * @param {function} successCallback Success callback function
 * @param {function} failureCallback Failure callback function
 * @param {function} errorCallback Error callback function
 */
function forgotPassword(email, successCallback, failureCallback, errorCallback) {
    fetchApi(`${baseurl}/user/forgotpassword/?email=${email}`, {
    }, 'get', null, successCallback, failureCallback, errorCallback)
}

/**
 * Reset password setting procedure 
 * 
 * @param {string} token Access token
 * @param {string} password New password 
 * @param {function} successCallback Success callback function
 * @param {function} failureCallback Failure callback function
 * @param {function} errorCallback Error callback function
 */
export function resetPassword(token, password, successCallback, failureCallback, errorCallback) {
    fetchApi(`${baseurl}/user/forgotpassword/`, {

    }, 'post', {
        "token": token,
        "password": password
    }, successCallback, failureCallback, errorCallback)
}

export function saveFencingData(token, thing_id, coordinates_list, fencing_name, successCallback, failureCallback, errorCallback) {
    fetchApi(`${baseurl}/things/geofence/${thing_id}`,
        { "Authorization": token },
        'post', { coordinates_list: coordinates_list, name: fencing_name },
        successCallback, failureCallback, errorCallback)
}

export function updateFencingData(token, thing_id, id, requestBody, successCallback, failureCallback, errorCallback) {
    fetchApi(`${baseurl}/things/geofence/${thing_id}/${id}`,
        { "Authorization": token },
        'post', requestBody,
        successCallback, failureCallback, errorCallback)
}

export function fetchFencing(token, thing_id, successCallback, failureCallback, errorCallback) {
    fetchApi(`${baseurl}/things/geofence/${thing_id}`,
        { "Authorization": token },
        'get', null, successCallback, failureCallback, errorCallback)
}

export function deleteFencing(token, thing_id, id, successCallback, failureCallback, errorCallback) {
    fetchApi(`${baseurl}/things/geofence/${thing_id}/${id}`,
        { "Authorization": token },
        'delete', null, successCallback, failureCallback, errorCallback)
}

export function fetchFencingAlerts(token, thing_id, fromDate, toDate, successCallback, failureCallback, errorCallback) {
    fetchApi(`${baseurl}/things/geofence/${thing_id}/alerts?from_date=${fromDate}&to_date=${toDate}`,
        { "Authorization": token },
        'get', null, successCallback, failureCallback, errorCallback)
}
/**
 * Welcome page stats and information
 * 
 * @param {string} token Access token
 * @param {function} successCallback Success callback
 * @param {function} failureCallback Failure callback
 * @param {function} errorCallback Error callback
 */
export function dashboard(token, successCallback, failureCallback, errorCallback) {
    fetchApi(`${baseurl}/dashboard/`,
        {
            "Authorization": token
        }, 'get', null, successCallback, failureCallback, errorCallback)
}

/**
 *
 * Get a list of Available Permissions for a given project
 * 
 * @param {string} token - Bearer token for authorization
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
const listAvailablePermission = (token, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/management/permission/`,
        {
            "Authorization": token,
        },
        "get", null, successCallback, failureCallback, errorCallback
    )
}

/**
 *
 * Get a list of Available Groups for a given project
 * 
 * @param {string} token - Bearer token for authorization
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
const listAvailableGroup = (token, organisation_Id, pageNumber, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/management/group/?org_id=${organisation_Id}&page=${pageNumber}`,
        {
            "Authorization": token,
        },
        "get", null, successCallback, failureCallback, errorCallback
    )
}

/**
 *
 * Create a Groups for a given project
 * 
 * @param {string} token - Bearer token for authorization
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
const createGroup = (token, name, organisation, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/management/group/`,
        {
            "Authorization": token,
        },
        "post", { "name": name, "organisation": organisation }, successCallback, failureCallback, errorCallback
    )
}

/**
 *
 * Update a Groups for a given project
 * 
 * @param {string} token - Bearer token for authorization
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
const modifyGroup = (token, group_id, name, organisation, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/management/group/`,
        {
            "Authorization": token,
        },
        "put", { "group_id": group_id, "name": name, "organisation": organisation }, successCallback, failureCallback, errorCallback
    )
}

/**
 *
 * Remove a Groups for a given project
 * 
 * @param {string} token - Bearer token for authorization
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
const removeGroup = (token, group_id, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/management/group/`,
        {
            "Authorization": token,
        },
        "delete", { "group_id": group_id }, successCallback, failureCallback, errorCallback
    )
}

/**
 *
 * Get a list of Available Roles for a given project
 * 
 * @param {string} token - Bearer token for authorization
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
const listAvailableRoles = (token, organisation_Id, pageNumber, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/management/role/?org_id=${organisation_Id}&page=${pageNumber}`,
        {
            "Authorization": token,
        },
        "get", null, successCallback, failureCallback, errorCallback
    )
}

/**
 *
 * Create a Roles for a given project
 * 
 * @param {string} token - Bearer token for authorization
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
const createRoles = (token, name, organisation, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/management/role/`,
        {
            "Authorization": token,
        },
        "post", { "name": name, "organisation": organisation }, successCallback, failureCallback, errorCallback
    )
}

/**
 *
 * Update a Roles for a given project
 * 
 * @param {string} token - Bearer token for authorization
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
const modifyRoles = (token, role_id, name, organisation, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/management/role/`,
        {
            "Authorization": token,
        },
        "put", { "role_id": role_id, "name": name, "organisation": organisation }, successCallback, failureCallback, errorCallback
    )
}

/**
 *
 * Remove a Role for a given project
 * 
 * @param {string} token - Bearer token for authorization
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
const removeRoles = (token, role_id, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/management/role/`,
        {
            "Authorization": token,
        },
        "delete", { "role_id": role_id }, successCallback, failureCallback, errorCallback
    )
}

/**
 *
 * Get Permissions of  a Role for a given project
 * 
 * @param {string} token - Bearer token for authorization
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
const fetchRolePermissions = (token, role_id, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/management/permission_role/?role_id=${role_id}`,
        {
            "Authorization": token,
        },
        "get", null, successCallback, failureCallback, errorCallback
    )
}


/**
 *
 * Create Role Permissions 
 * 
 * @param {string} token - Bearer token for authorization
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
const makeRolePermission = (token, role_id, permission_id, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/management/permission_role/`,
        {
            "Authorization": token,
        },
        "post",
        {
            "role_id": role_id,
            "permission_id": permission_id
        }
        , successCallback, failureCallback, errorCallback
    )
}

/**
 *
 * Delete Role Permissions 
 * 
 * @param {string} token - Bearer token for authorization
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
const removeRolePermission = (token, role_permission_id, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/management/permission_role/`,
        {
            "Authorization": token,
        },
        "delete",
        {
            "role_permission_id": role_permission_id,
        }
        , successCallback, failureCallback, errorCallback
    )
}

export function listImageStream(accessToken, projectId, successCallback, failureCallback, errorCallback) {
    fetchApi(`${baseurl}/imagestream/${projectId}/streams/`, {
        "Authorization": accessToken
    }, 'get', null, successCallback, failureCallback, errorCallback)
}

export function createImageStream(accessToken, projectId, imageStreamData, successCallback, failureCallback, errorCallback) {
    fetchApi(`${baseurl}/imagestream/${projectId}/stream/`, {
        "Authorization": accessToken
    }, 'post', imageStreamData, successCallback, failureCallback, errorCallback)
}

/**
 *
 * Create Role_GROUP 
 * 
 * @param {string} token - Bearer token for authorization
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
const makeRoleGroup = (token, role_id, group_id, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/management/role_group/`,
        {
            "Authorization": token,
        },
        "post",
        {
            "role_id": role_id,
            "group_id": group_id
        }
        , successCallback, failureCallback, errorCallback
    )
}

export function deleteImageStream(accessToken, projectId, imageStreamId, successCallback, failureCallback, errorCallback) {
    fetchApi(`${baseurl}/imagestream/${projectId}/streams/${imageStreamId}/delete/`, {
        "Authorization": accessToken
    }, 'DELETE', null, successCallback, failureCallback, errorCallback)
}
export function updateImageStream(accessToken, projectId, imageStreamId, body, successCallback, failureCallback, errorCallback) {
    fetchApi(`${baseurl}/imagestream/${projectId}/streams/${imageStreamId}/edit/`, {
        "Authorization": accessToken
    }, 'POST',
        {
            "name": body.name,
            "frequency": body.frequency,
            "preview_number": body.preview_number,
            "storage_period": body.storage_period
        },
        successCallback, failureCallback, errorCallback)
}
export function imageStreamDetail(accessToken, projectId, imageStreamId, successCallback, failureCallback, errorCallback) {
    fetchApi(`${baseurl}/imagestream/${projectId}/streams/${imageStreamId}/`, {
        "Authorization": accessToken
    }, 'get', null, successCallback, failureCallback, errorCallback)
}

/**
 *
 * Get a list of Available User of an Organization
 * 
 * @param {string} token - Bearer token for authorization
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
const listAvailableUsers = (token, org_id, pageNumber, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/organisation/user/?org_id=${org_id}&page=${pageNumber}`,
        {
            "Authorization": token,
        },
        "get", null, successCallback, failureCallback, errorCallback
    )
}
/**
 *
 * Get a list of Available User of an Organization
 * 
 * @param {string} token - Bearer token for authorization
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
const getAllUserGroup = (token, user_id, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/management/group/user/?user_id=${user_id}`,
        {
            "Authorization": token,
        },
        "get", null, successCallback, failureCallback, errorCallback
    )
}

/**
 *
 * Get a list of Available User of an Organization
 * 
 * @param {string} token - Bearer token for authorization
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
const deleteUserGroup = (token, group_user_id, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/management/group_user/`,
        {
            "Authorization": token,
        },
        "delete",
        { "group_user_id": group_user_id }, successCallback, failureCallback, errorCallback
    )
}

/**
 *
 * Get a list of Available User of an Organization
 * 
 * @param {string} token - Bearer token for authorization
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
const createUserGroup = (token, user_id, group_id, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/management/group_user/`,
        {
            "Authorization": token,
        },
        "post",
        { "group_id": group_id, "user_id": user_id }, successCallback, failureCallback, errorCallback
    )
}

/**
 *
 * Get a list of Available User of an Organization
 * 
 * @param {string} token - Bearer token for authorization
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
const getAllGroupRoles = (token, group_id, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/management/role_group/?group_id=${group_id}`,
        {
            "Authorization": token,
        },
        "get", null, successCallback, failureCallback, errorCallback
    )
}

/**
 *
 * Get a list of Available User of an Organization
 * 
 * @param {string} token - Bearer token for authorization
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 *  
 */
const deleteGroupRole = (token, role_group_id, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/management/role_group/`,
        {
            "Authorization": token,
        },
        "delete",
        { "role_group_id": role_group_id }, successCallback, failureCallback, errorCallback
    )
}

/**
 * Create a new user under an organisation for a enterprise account.
 * 
 * @param {string} token Access token
 * @param {string} org_id Organisation ID
 * @param {object} userObject User information object
 * @param {function} successCallback Success callback function
 * @param {function} failureCallback Failure callback function
 * @param {function} errorCallback Error callback function
 */
const createOrgUser = (token, org_id, userObject, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/organisation/user/`,
        {
            "Authorization": token,
        },
        "post",
        {
            "org_id": org_id,
            "user": {
                "name": userObject.name,
                "email": userObject.email,
                "password": userObject.password,
                "phone": userObject.phone,
                "country": userObject.country,
                "timezone": userObject.timezone
            }
        }, successCallback, failureCallback, errorCallback
    )
}

export const getOrgUsers = (token, org_id, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/organisation/user/?org_id=${org_id}`,
        {
            "Authorization": token,
        },
        "get", null, successCallback, failureCallback, errorCallback
    )
}

export function removeUserFromOrg(token, org_id, user_id, successCallback, failureCallback, errorCallback) {
    fetchApi(`${baseurl}/organisation/user/`,
        {
            "Authorization": token,
        },
        "delete",
        {
            "user_id": user_id,
            "org_id": org_id
        }, successCallback, failureCallback, errorCallback
    )
}
const transferThingToInternalProject = (token, thing_id, project_id, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/things/transfer/`,
        {
            "Authorization": token,
        },
        "put",
        {
            "project_id": project_id,
            "thing_id": thing_id
        }

        , successCallback, failureCallback, errorCallback
    )
}

const transferThingToExternalProject = (token, thing_id, project_id, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/projects/${project_id}/transfer/${thing_id}/`,
        {
            "Authorization": token,
        },
        "get",
        null, successCallback, failureCallback, errorCallback
    )
}

export const createDashboard = (token, name, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/dashboards/create/`, {
        "Authorization": token,
    },
        "post",
        {
            "name": name
        }, successCallback, failureCallback, errorCallback
    )
}

export const createAnalyticsKPI = (token, name, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/dashboards/create/`, {
        "Authorization": token,
    },
        "post",
        {
            "name": name
        }, successCallback, failureCallback, errorCallback
    )
}

export const listDashboards = (token, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/dashboards/all/`,
        {
            "Authorization": token,
        },
        "get",
        null, successCallback, failureCallback, errorCallback
    )
}

export const listKPIs = (token, projectId, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/analytics/${projectId}/kpi/`,
        {
            "Authorization": token,
        },
        "get",
        null, successCallback, failureCallback, errorCallback
    )
}

export const KPIDetails = (token, projectId, kpiId, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/analytics/${projectId}/kpi/${kpiId}/`,
        {
            "Authorization": token,
        },
        "get",
        null, successCallback, failureCallback, errorCallback
    )
}

export const deleteDashboard = (token, dashboard_id, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/dashboards/delete/${dashboard_id}/`,
        {
            "Authorization": token,
        },
        "delete",
        null, successCallback, failureCallback, errorCallback
    )
}
export const deleteAnalytics = (token, KPI_id, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/dashboards/delete/${KPI_id}/`,
        {
            "Authorization": token,
        },
        "delete",
        null, successCallback, failureCallback, errorCallback
    )
}

export const editDashboard = (token, dashboard_id, name, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/dashboards/update/${dashboard_id}/`, {
        "Authorization": token,
    },
        "post",
        {
            "name": name
        }, successCallback, failureCallback, errorCallback
    )
}

export const saveDashboardLayout = (token, dashboard_id, ui_config, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/dashboards/update/${dashboard_id}/`, {
        "Authorization": token,
    },
        "post",
        {
            "ui_config": ui_config
        }, successCallback, failureCallback, errorCallback
    )
}


export const editedAnalytics = (token, KPI_id, name, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/dashboards/update/${KPI_id}/`, {
        "Authorization": token,
    },
        "post",
        {
            "name": name
        }, successCallback, failureCallback, errorCallback
    )
}

/**
 * Get details of a single dashboard
 * 
 * @param {string} token Access token
 * @param {string} dashboard_id Dashboard id 
 * @param {function} successCallback Success callback function
 * @param {function} failureCallback Failure callback function
 * @param {function} errorCallback Error callback function
 */
export const getDashboard = (token, dashboard_id, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/dashboards/view/${dashboard_id}/`, {
        "Authorization": token,
    },
        "get", null, successCallback, failureCallback, errorCallback
    )
}


/**
 * List widget types acceptable to add widget
 * 
 * @param {function} successCallback Success callback function
 * @param {function} failureCallback Failure callback funcation
 * @param {function} errorCallback Error callback function
 */
export const listWidgetTypes = (successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/dashboards/widget/types/`,
        {},
        "get",
        null, successCallback, failureCallback, errorCallback
    )
}


/**
 * Add a widget to the dashboard identified by Dashboard ID
 * 
 * @param {string} token Access token
 * @param {string} dashboard_id Dashboard ID
 * @param {string} str_rep String representation of the widget
 * @param {function} successCallback Success callback function
 * @param {function} failureCallback Failure callback function
 * @param {function} errorCallback Error callback function
 */
export const addWidget = (token, dashboard_id, str_rep, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/dashboards/add/widget/${dashboard_id}/`, {
        "Authorization": token,
    },
        "post",
        {
            "str_rep": str_rep
        }, successCallback, failureCallback, errorCallback
    )
}

/**
 * 
 * Get camera details
 * 
 * @param {string} token Access token
 * @param {string} camera_id Camera ID
 * @param {function} successCallback Callback function
 * @param {function} failureCallback Callback function
 * @param {function} errorCallback Callback function
 */
export const getCameraDetails = (token, camera_id, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/camera/${camera_id}/`,
        {
            Authorization: token
        },
        "get",
        null, successCallback, failureCallback, errorCallback
    )
}

/**
 * Remove widget from a dashboard 
 * 
 * @param {string} token Access token
 * @param {string} dashboard_id Dashboard ID
 * @param {string} widget_id Widget ID to be deleted
 * @param {function} successCallback Success callback function
 * @param {function} failureCallback Failure callback function
 * @param {function} errorCallback Error callback function
 */
export const removeWidget = (token, dashboard_id, widget_id, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/dashboards/remove/widget/${dashboard_id}/${widget_id}/`,
        {
            "Authorization": token

        }, "delete", null, successCallback, failureCallback, errorCallback)

}

/**
 * List shared keys 
 * 
 * @param {string} token Access token
 * @param {string} dashboard_id Dashboard ID
 * @param {function} successCallback Success callback function
 * @param {function} failureCallback Failure callback function
 * @param {function} errorCallback Error callback function
 */
export const listDashboardShareKeys = (token, dashboard_id, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/dashboards/share/${dashboard_id}/`,
        {
            "Authorization": token

        }, "get", null, successCallback, failureCallback, errorCallback)

}

/**
 * Generate a shared key 
 * 
 * @param {string} token Access token
 * @param {string} dashboard_id Dashboard ID
 * @param {function} successCallback Success callback function
 * @param {function} failureCallback Failure callback function
 * @param {function} errorCallback Error callback function
 */
export const generateDashboardShareKey = (token, dashboard_id, ttl, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/dashboards/share/${dashboard_id}/`,
        {
            "Authorization": token

        }, "post", { "ttl": ttl }, successCallback, failureCallback, errorCallback)

}


/**
 * Delete a shared key 
 * 
 * @param {string} token Access token
 * @param {string} dashboard_id Dashboard ID
 * @param {string} share_key_id Share key ID
 * @param {function} successCallback Success callback function
 * @param {function} failureCallback Failure callback function
 * @param {function} errorCallback Error callback function
 */
export const deleteDashboardShareKey = (token, dashboard_id, share_key_id, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/dashboards/share/${dashboard_id}/?sid=${share_key_id}`,
        {
            "Authorization": token

        }, "delete", null, successCallback, failureCallback, errorCallback)

}

// --------------------------------API's for Package------------------------------//
/**
 * Display all packages 
 * 
 * @param {string} token Access token
 * @param {function} successCallback Success callback function
 * @param {function} failureCallback Failure callback function
 * @param {function} errorCallback Error callback function
 */
export const displayAllPackages = (token, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/billing/user/package/`,
        {
            "Authorization": token
        }, "get", null, successCallback, failureCallback, errorCallback)

}

/**
 * Check users billing account is present or not 
 * 
 * @param {string} token Access token
 * @param {function} successCallback Success callback function
 * @param {function} failureCallback Failure callback function
 * @param {function} errorCallback Error callback function
 */
export const checkBillingAccount = (token, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/billing/account/`,
        {
            "Authorization": token

        }, "get", null, successCallback, failureCallback, errorCallback)

}

/**
 * Create Billing Account
 * 
 * @param {string} token Access token
 * @param {function} successCallback Success callback function
 * @param {function} failureCallback Failure callback function
 * @param {function} errorCallback Error callback function
 */
export const createBillingAccount = (token, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/billing/account/`,
        {
            "Authorization": token

        }, "post", null, successCallback, failureCallback, errorCallback)

}

/**
 * Fetch currenct subscription
 * 
 * @param {string} token Access token
 * @param {function} successCallback Success callback function
 * @param {function} failureCallback Failure callback function
 * @param {function} errorCallback Error callback function
 */
export const fetchSubscription = (token, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/billing/subscription/current/`,
        {
            "Authorization": token

        }, "get", null, successCallback, failureCallback, errorCallback)

}

/**
 * Fetch all Add on features
 * 
 * @param {string} token Access token
 * @param {function} successCallback Success callback function
 * @param {function} failureCallback Failure callback function
 * @param {function} errorCallback Error callback function
 */
export const fetchAddOnFeatures = (token, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/billing/user/feature/`,
        {
            "Authorization": token

        }, "get", null, successCallback, failureCallback, errorCallback)

}

/**
 * Fetch package features package-wise
 * 
 * @param {string} token Access token
 * @param {function} package_id package-id
 * @param {function} successCallback Success callback function
 * @param {function} failureCallback Failure callback function
 * @param {function} errorCallback Error callback function
 */
export const fetchPackageFeatures = (token, package_id, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/billing/user/package_features/?package_id=${package_id}`,
        {
            "Authorization": token

        }, "get", null, successCallback, failureCallback, errorCallback)

}

/**
 * Send billing subscription data in post method
 * 
 * @param {string} token Access token
 * @param {function} subscription_data package subscription data: acc_no, pack_id, addon_features
 * @param {function} successCallback Success callback function
 * @param {function} failureCallback Failure callback function
 * @param {function} errorCallback Error callback function
 */
export const billingSubscription = (token, subscription_data, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/billing/subscription/`,
        {
            "Authorization": token

        }, "post", subscription_data, successCallback, failureCallback, errorCallback)
}

/**
 * fetch previous subscription history
 * 
 * @param {string} token Access token
 * @param {function} account_number billing account number
 * @param {function} successCallback Success callback function
 * @param {function} failureCallback Failure callback function
 * @param {function} errorCallback Error callback function
 */
export const subscriptionHistory = (token, account_number, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/billing/subscription/?account_number=${account_number}`,
        {
            "Authorization": token

        }, "get", null, successCallback, failureCallback, errorCallback)
}

/**
 * send existing subscription extra addon data in post method
 * 
 * @param {string} token Access token
 * @param {function} addon_data addon data: feature_id and purchase_value
 * @param {function} successCallback Success callback function
 * @param {function} failureCallback Failure callback function
 * @param {function} errorCallback Error callback function
 */
export const purchaseExtraAddon = (token, addon_data, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/billing/addon/`,
        {
            "Authorization": token

        }, "post", addon_data, successCallback, failureCallback, errorCallback)
}

// ----------API for alarmLog-----------//

/**
 * api to fetch alarm logs w.r.t selected start-date and end-date
 * 
 * @param {string} token Access token
 * @param {function} project_id project id
 * @param {function} alarm_id alarm id
 * @param {function} start_date start date to display alarm log
 * @param {function} end_date end date to display alarm log
 * @param {function} successCallback Success callback function
 * @param {function} failureCallback Failure callback function
 * @param {function} errorCallback Error callback function
 */
export function getAlarmHistory(token, project_id, alarm_id, start_date, end_date, successCallback, failureCallback, errorCallback) {
    // fetchApi(`${baseurl}/projects/${project_id}/alarmlog/${alarm_id}/`,
    fetchApi(`${baseurl}/projects/${project_id}/alarmlog/${alarm_id}/?start_date=${start_date}&end_date=${end_date}`,
        {
            "Authorization": token
        }, 'get', null, successCallback, failureCallback, errorCallback)
}

/**
 * api to fetch alarm logs w.r.t selected start-date and end-date
 * 
 * @param {string} token Access token
 * @param {function} log_id log id
 * @param {function} successCallback Success callback function
 * @param {function} failureCallback Failure callback function
 * @param {function} errorCallback Error callback function
 */
export function acknowledgeAlarmLog(token, log_id, successCallback, failureCallback, errorCallback) {
    fetchApi(`${baseurl}/projects/alarm/acknowledge/`,
        {
            "Authorization": token
        }, 'put', log_id, successCallback, failureCallback, errorCallback)
}

/**
 * Link user profile integration with google and amazon
 * 
 * @param {string} token Access token
 * @param {function} profileMail linked profile's mail-id
 * @param {function} successCallback Success callback function
 * @param {function} failureCallback Failure callback function
 * @param {function} errorCallback Error callback function
 */
export const userProfileIntegration = (token, profileMail, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/user/profile/integration/`,
        {
            "Authorization": token

        }, "post", profileMail, successCallback, failureCallback, errorCallback)
}

/**
 * Unlink user profile integration with google and amazon
 * 
 * @param {string} token Access token
 * @param {function} profileMail unlink profile type
 * @param {function} successCallback Success callback function
 * @param {function} failureCallback Failure callback function
 * @param {function} errorCallback Error callback function
 */
export const userProfileUnlink = (token, profileType, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/user/profile/integration/`,
        {
            "Authorization": token

        }, "put", profileType, successCallback, failureCallback, errorCallback)
}

/**
 * fetch status of user linked to google and amazon 
 * 
 * @param {string} token Access token
 * @param {function} successCallback Success callback function
 * @param {function} failureCallback Failure callback function
 * @param {function} errorCallback Error callback function
 */
export const userLinkedStatus = (token, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/user/profile/integration/`,
        {
            "Authorization": token

        }, "get", null, successCallback, failureCallback, errorCallback)
}

/**
 * api to cancel subscription
 * 
 * @param {string} token Access token
 * @param {function} subscriptionData subscription_id and status= cancel
 * @param {function} successCallback Success callback function
 * @param {function} failureCallback Failure callback function
 * @param {function} errorCallback Error callback function
 */
export function subscriptionCancel(token, subscriptionData, successCallback, failureCallback, errorCallback) {
    fetchApi(`${baseurl}/billing/subscription/`,
        {
            "Authorization": token
        }, 'put', subscriptionData, successCallback, failureCallback, errorCallback)
}

/**
 * Update app secret key
 * 
 * @param {string} token Access token
 * @param {function} appId app id
 * @param {function} successCallback Success callback function
 * @param {function} failureCallback Failure callback function
 * @param {function} errorCallback Error callback function
 */
export function updateAppSecretKey(token, appId, successCallback, failureCallback, errorCallback) {
    fetchApi(`${baseurl}/api/v1/key/`,
        {
            "Authorization": token
        }, 'PATCH',
        {
            app_id: appId
        }, successCallback, failureCallback, errorCallback)
}

/**
 * Update app name
 * 
 * @param {string} token Access token
 * @param {function} appId app id
 * @param {function} successCallback Success callback function
 * @param {function} failureCallback Failure callback function
 * @param {function} errorCallback Error callback function
 */
export function updateAppName(token, appName, appId, successCallback, failureCallback, errorCallback) {

    fetchApi(`${baseurl}/api/v1/app/`,
        {
            "Authorization": token
        }, 'PATCH',
        {
            app_name: appName,
            app_id: appId
        }, successCallback, failureCallback, errorCallback)
}

/**
 * set thing properties
 * 
 * @param {string} token Access token
 * @param {function} propertyData thing_id, ssid, password
 * @param {function} successCallback Success callback function
 * @param {function} failureCallback Failure callback function
 * @param {function} errorCallback Error callback function
 */
export function setThingProperty(token, propertyData, successCallback, failureCallback, errorCallback) {
    fetchApi(`${baseurl}/things/properties/`,
        {
            "Authorization": token
        }, 'put', propertyData, successCallback, failureCallback, errorCallback)
}

/**
 * Get CMS Content from the api
 * 
 * @param {function} successCallback 
 * @param {function} failureCallback Failure callback function
 * @param {function} errorCallback Error callback function
 */
export function getCMSContent(successCallback, failureCallback, errorCallback) {
    fetchApi(`${baseurl}/cms/`, { "Content-Type": 'application/json' }, 'get', null, successCallback, failureCallback, errorCallback)


}

export function getWelcomePageDetails(token, resources, successCallback, failureCallback, errorCallback) {
    fetchApi(`${baseurl}/resource/details?resources=${resources}`,
        {
            "Authorization": token
        }, 'get', null, successCallback, failureCallback, errorCallback)
}

export function getUserWiseThingList(token, successCallback, failureCallback, errorCallback) {
    fetchApi(`${baseurl}/things/groupsubgroup/`,
        {
            "Authorization": token
        }, 'get', null, successCallback, failureCallback, errorCallback)
}
export function getalaramgroupsubgroup(token, successCallback,pid,gid) {
    fetchApi(`${baseurl}/projects/${pid}/alarm-details/${gid}/`,
        {
            "Authorization": token
        }, 'get', null, successCallback, null, null)
}

export function getThingGPS_Details(token, thing_id, successCallback, failureCallback, errorCallback) {
    fetchApi(`${baseurl}/gps/${thing_id}`,
        {
            "Authorization": token
        }, 'get', null, successCallback, failureCallback, errorCallback)
}

export default api;
export {
    transferThingToInternalProject,
    transferThingToExternalProject,
    createOrgUser,
    deleteGroupRole,
    getAllGroupRoles,
    createUserGroup,
    deleteUserGroup,
    getAllUserGroup,
    listAvailableUsers,
    makeRoleGroup,
    removeRolePermission,
    fetchRolePermissions,
    makeRolePermission,
    removeRoles,
    modifyRoles,
    createRoles,
    listAvailableRoles,
    listAvailableGroup,
    createGroup,
    listAvailablePermission,
    ws_baseurl,
    modifyGroup,
    removeGroup,
    forgotPassword,
    fetchApi,
    getUserProfile,
    getProfileData,
    createProject,
    updateProject,
    listProjects,
    listNProjects,
    listCameras,
    createCamera,
    listThings,
    registerThing,
    getThingDetails,
    getThingDatalog,
    verifyToken,
    createTrend,
    getTrends,
    deleteTrend,
    createAlarm,
    getAlarms,
    deleteAlarm,
    getTrendData,
    modifyAlarm,
    modifyUserProfile,
    updateUserPassword,
    getUserLogs,
    createApp,
    getAppsList,
    revokeApp,
    getBrands,
    getIRBlasters,
    registerIRBlaster,
    updateIRBlaster,
    controlThing,
    deleteProject,
    deleteProjectThing,
    updateThing,
    deleteIRBlasters,
    deleteCamera,
    modifyCamera,
    createWebHook,
    updateWebHook,
    deleteWebHook,
    getWebHook,
    getProcess,
    updateProcess,
    removeProcess,
    createProcess,
    getAllScopes,
    onExportCSV
};
export const ITEM_PER_PAGE_LIST = [10, 20];
export const DEFAULT_PAGE_NUMBER = 1;